<template>
  <div>

  </div>
</template>
<script>

  export default {
    name: 'report',
    data() {
      return {
      };
    },
    mounted() {
      this.initWeeklyDatas();
    },
    methods: {
      initWeeklyDatas(){
        var date = this.$route.query.date;
        if(date < 20240826){
          this.$router.push({ path: "/weekly/dj/report/v1", query: this.$route.query });
        }else{
          this.$router.push({ path: "/weekly/dj/report/v2", query: this.$route.query });
        }
      },
    },
  };
</script>
