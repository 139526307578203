<template>
  <div class="el-main-weekly" >
    <div  style="display:flex;justify-content: center;" >
      <div  class="weekly-dj-background">
        <!--头部模块-->
        <div>
          <img style="position: absolute;left: 3px;top: 21px;width: 180px;height: 32px" src="@/assets/image/weekly/dj/logo.png" alt=""/>
          <div class="weekly-div-background-date">
            <div style="height: 15px"></div>
            <div>
              <span  class="weekly-punish-date">{{year}}/{{weeklyInfo.beginDate}}-{{weeklyInfo.endDate}}</span>
            </div>
          </div>
          <img  style="position: absolute;left: 15px;top: 73px;width: 199px;height: 132px" src="@/assets/image/weekly/dj/wenan.png" alt=""/>
          <img  style="position: absolute;left: 210px;top: 68px;width: 162px;height: 183px" src="@/assets/image/weekly/dj/chv2.png" alt=""/>
          <img  style="position: absolute;left: 17px;top: 211px;width: 241px;height: 31px" src="@/assets/image/weekly/dj/neibu.png" alt=""/>
        </div>


        <img v-if="this.weeklyDate == 20240826"  style="position: absolute;left: 7px;top: 253px;width: 359px;height: 198px" src="@/assets/image/weekly/dj/jtzmv2.png" alt=""/>

        <!--本周处罚详情-->
        <div v-show="!clickFlag">

          <img v-if="this.weeklyDate == 20240826" style="position: absolute;z-index:-999;left: 22px;top: 513px;width: 330px;height: 88px" src="@/assets/image/weekly/dj/di.png" alt=""/>
          <img v-show="!weeklyInfo.haveSpFlag" v-else style="position: absolute;z-index:-999;left: 22px;top: 313px;width: 330px;height: 88px" src="@/assets/image/weekly/dj/di.png" alt=""/>
          <div>
            <span  :style="this.weeklyDate == 20240826 ? 'top:465px' : 'top:265px'" @click="filtrate(true)"  class="weekly-tag-right-font">{{year}}年处罚分析</span>
            <span :style="this.weeklyDate == 20240826 ? 'top:465px' : 'top:265px'" class="weekly-tag-right-font1">本周处罚详情</span>
            <img v-if="this.weeklyDate == 20240826" style="position: absolute;left: 10px;top: 453px;width: 361px;height: 55px" src="@/assets/image/weekly/dj/right-tab-s.png" alt=""/>
            <img v-else style="position: absolute;left: 10px;top: 253px;width: 361px;height: 55px" src="@/assets/image/weekly/dj/right-tab-s.png" alt=""/>
          </div>
          <div :style="this.weeklyDate == 20240826 ? 'margin-top:455px' : 'margin-top:255px'"  class="weekly-tag-background-x">
            <div v-if="weeklyInfo.config.bzfdsFlag">

              <div style="height: 20px"></div>
              <div style="width: 331px;background: linear-gradient(#F3E6D8,#F4E0BC);margin-left: 12px;border-radius: 10px;">

                <!--特别关注模块-->
                <div style="margin-left: -13px;margin-top: -25px" v-if="weeklyInfo.haveSpFlag">
                  <div  class="weekly-div-background-spc" >
                  </div>

                  <div style="width: 329px;margin-left: 13px;margin-top: -43px">
                    <div  class="weekly-div-background-sp" >
                      <div style="height: 15px"></div>
                      <div>
                        <div v-for="item1 in weeklyInfo.djSplList"  style="margin-bottom: 20px;display: flex;">
                          <div style="margin-left: 15px">
                            <img src="@/assets/image/weekly/dj/yuan-zi.png" style="margin-top: 5px;width: 15px; flex: 0 0 11.5px; height: 15px" alt=""/>
                          </div>

                          <div v-if="item1.assList.length >= 2 || (item1.assList.length == 1 &&  item1.otherLink != '')"  >
                            <el-popover
                                width="250"
                                placement="bottom"
                                popper-class="custom-popover"
                                trigger="click">
                              <div style="width: 250px;">
                                <div style="text-align: center;font-size: 15px;cursor: pointer;color: #98783D;" @click="punishSelectDetail(item2.punId)" v-for="item2,index in item1.assList">
                                  <span style="line-height: 40px; ">{{item2.fileNum}}</span>
                                  <div v-if=" item1.assList.length != index+1" style="height: 1px;width: 250px;background-color: #E2E5EB"></div>
                                </div>
                                <div v-if="item1.otherLink != ''" style="text-align: center;font-size: 15px;cursor: pointer;color: #98783D;" @click="punishOtherDetail(item1.otherLink)" >
                                  <div style="height: 1px;width: 250px;background-color: #E2E5EB"></div>
                                  <span style="line-height: 40px; ">{{item1.otherTitle}}</span>
                                </div>
                              </div>
                              <div v-if="item1.assList.length >= 0" slot="reference" @click="">
                                <div style="width: 284px;font-size: 16px;font-weight: 400;line-height: 24px;font-family: 思源黑体;color: black;margin-left: 2.5px;cursor: pointer;" v-html="item1.content" >
                                </div>
                              </div>
                            </el-popover>
                          </div>
                          <div v-else>
                            <div @click="punishDetail(item1)">
                              <div style="width: 284px;font-size: 16px;font-weight: 400;line-height: 24px;font-family: 思源黑体;margin-left: 2.5px;cursor: pointer;" v-html="item1.content" >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style="height: 5px"></div>
                      </div>

                      <div style="margin-left: 15px;margin-top: -10px">
                        <span class="weekly-font-table-beizhu">点击特别关注内容查看详细信息</span>
                        <div style="height: 10px"></div>
                      </div>
                    </div>
                    <div style="height: 1px"></div>
                  </div>
                  <div  style="height: 20px" ></div>
                </div>


                <div class="weekly-table-bankgroud">
                  <div style="text-align: center">
                    <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                    <span class="weekly-font-title"> 本周罚单数 </span>
                    <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  </div>

                  <div style="width: 300px;margin-left: 17px;margin-top: -10px;">
                    <span class="weekly-font-table">本周（{{weeklyInfo.tableDetailRespVO.beginDate}}-{{weeklyInfo.tableDetailRespVO.endDate}}），
                      <span style="font-weight: bold">金监局及其派出机构</span>对全国各地区{{weeklyInfo.tableDetailRespVO.subNameStr}}开出的罚单数如下：</span>
                  </div>
                  <div style="margin-bottom: 5px;margin-left: 15px">
                    <span class="weekly-font-table-beizhu">列表中未展示的地区本周无罚单</span>
                  </div>

                  <div class="regional-table">
                    <div class="regional-table-bankground">
                      <el-table
                              :data="weeklyInfo.tableList"
                              border
                              :row-style="{height: 30+'px',background: 'transparent !important'}"
                              :cell-style="{padding:0+'px','text-align':'center',background: 'transparent !important','font-size': '14px','font-family': '思源黑体','font-weight': '400',color: 'rgb(152, 120, 61)','border-color':'rgb(228, 198, 144)'}"
                              :header-cell-style="{height: 30+'px',padding:0+'px',background:'rgb(232, 207, 164)','text-align':'center','font-size': '14px','font-family': '思源黑体','font-weight': '700',color: 'rgb(149, 118, 58)','border-color':'rgb(228, 198, 144)'}"
                              class="weekly-el-table-st"
                      >
                        <el-table-column prop="subName" label="机构" width="90">
                          <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName,scope.row.number)" :style="scope.row.number == 0 ? {}:{cursor: 'pointer'}">{{ scope.row.subName }}</div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="number" label="数量" width="60">
                          <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName,scope.row.number)" :style="scope.row.number == 0 ? {color: '#15233B 100%','font-weight': '700'}:{ 'text-decoration':'underline','font-weight': '700',color: 'rgb(199, 49, 32)',cursor: 'pointer'}">{{ scope.row.number }}</div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="subName1"  label="机构" width="90">
                          <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName1,scope.row.number1)" :style="scope.row.number1 == 0 ? {}:{cursor: 'pointer'}">{{ scope.row.subName1 }}</div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="number1"  label="数量" width="60">
                          <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName1,scope.row.number1)" :style="scope.row.number1 == 0 ? {color: '#15233B 100%','font-weight': '700'}:{'text-decoration':'underline',color: 'rgb(199, 49, 32)','font-weight': '700',cursor: 'pointer'}">{{ scope.row.number1 }}</div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>

                  <!--<div style="width: 300px;border: 1px solid rgb(228, 198, 144);border-radius: 10px;margin-top: 20px;margin-left: 14px">-->
                    <!--<div style="height: 7px"></div>-->
                    <!--<div @click="goAnchor('#其他地区',1)" style="margin-left: 10px;width: 280px;cursor: pointer;">-->
                    <!--<span class="weekly-font-table">本周（{{weeklyInfo.tableDetailRespVO.beginDate}}-{{weeklyInfo.tableDetailRespVO.endDate}}），重点关注地区之外的{{weeklyInfo.tableDetailRespVO.subNameStr}}收到的罚单数合计-->
                    <!--<span  style="font-family: 思源黑体;font-weight: 900;color: rgb(199, 49, 32);text-decoration:underline">{{weeklyInfo.tableDetailRespVO.punNum}}</span> 张。</span>-->
                    <!--</div>-->
                    <!--<div style="height: 8px"></div>-->
                  <!--</div>-->
                  <div style="margin-top: 10px;margin-left: 15px">
                    <span class="weekly-font-table-beizhu">点击红色数字查看该地区处罚清单</span>
                  </div>


                  <!--*********************人民银行模块**********************-->

                  <div>
                    <div style="width: 300px;margin-left: 17px;margin-top: 10px;">
                    <span class="weekly-font-table">本周（{{weeklyInfo.bankTableDetailRespVO.beginDate}}-{{weeklyInfo.bankTableDetailRespVO.endDate}}），
                      <span style="font-weight: bold">人民银行及其分行</span>对保险公司开出罚单
                      <span  style="font-family: 思源黑体;font-weight: 900;color: rgb(199, 49, 32);text-decoration:underline">{{weeklyInfo.bankTableDetailRespVO.punNum}}</span>
                      张。</span>
                    </div>
                    <div v-if="weeklyInfo.bankTableDetailRespVO.punNum != 0">
                      <div style="margin-bottom: 5px;margin-left: 15px">
                        <span class="weekly-font-table-beizhu">列表中未展示的地区本周无罚单</span>
                      </div>
                      <div class="regional-table">
                        <div class="regional-table-bankground">
                          <el-table
                              :data="weeklyInfo.bankTableList"
                              border
                              :row-style="{height: 30+'px',background: 'transparent !important'}"
                              :cell-style="{padding:0+'px','text-align':'center',background: 'transparent !important','font-size': '14px','font-family': '思源黑体','font-weight': '400',color: 'rgb(152, 120, 61)','border-color':'rgb(228, 198, 144)'}"
                              :header-cell-style="{height: 30+'px',padding:0+'px',background:'rgb(232, 207, 164)','text-align':'center','font-size': '14px','font-family': '思源黑体','font-weight': '700',color: 'rgb(149, 118, 58)','border-color':'rgb(228, 198, 144)'}"
                              class="weekly-el-table-st"
                          >
                            <el-table-column prop="subName" label="机构" width="90">
                              <template slot-scope="scope">
                                <div @click="goAnchor('#人行',scope.row.number)" :style="scope.row.number == 0 ? {}:{cursor: 'pointer'}">{{ scope.row.subName }}</div>
                              </template>
                            </el-table-column>
                            <el-table-column prop="number" label="数量" width="60">
                              <template slot-scope="scope">
                                <div @click="goAnchor('#人行',scope.row.number)" :style="scope.row.number == 0 ? {color: '#15233B 100%','font-weight': '700'}:{ 'text-decoration':'underline','font-weight': '700',color: 'rgb(199, 49, 32)',cursor: 'pointer'}">{{ scope.row.number }}</div>
                              </template>
                            </el-table-column>
                            <el-table-column prop="subName1"  label="机构" width="90">
                              <template slot-scope="scope">
                                <div @click="goAnchor('#人行',scope.row.number1)" :style="scope.row.number1 == 0 ? {}:{cursor: 'pointer'}">{{ scope.row.subName1 }}</div>
                              </template>
                            </el-table-column>
                            <el-table-column prop="number1"  label="数量" width="60">
                              <template slot-scope="scope">
                                <div @click="goAnchor('#人行',scope.row.number1)" :style="scope.row.number1 == 0 ? {color: '#15233B 100%','font-weight': '700'}:{'text-decoration':'underline',color: 'rgb(199, 49, 32)','font-weight': '700',cursor: 'pointer'}">{{ scope.row.number1 }}</div>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </div>
                      <div style="margin-top: 10px;margin-left: 15px">
                        <span class="weekly-font-table-beizhu">点击红色数字查看该地区处罚清单</span>
                      </div>
                    </div>

                  </div>

                  <div style="height: 20px"></div>
                </div>
                <div style="height: 1px"></div>
              </div>


            </div>
            <div v-for="item,index in punishList" :id="item.subName" >
              <div  class="weekly-div-background-s" >
                <div style="text-align: center;">
                  <div style="height: 7px"></div>
                  <span class="weekly-font-other-title"> {{item.subName}} </span>
                </div>
              </div>
              <div style="width: 329px;background: linear-gradient(#F3E6D8,#F4E0BC);margin-left: 13px;border-radius: 0px 0px 20px 20px;">
                <div  class="weekly-div-background-z" >
                  <div v-if="index == 0 " style="padding-top: 10px;margin-left: 15px;margin-bottom: -10px">
                    <span class="weekly-font-table-beizhu">点击处罚信息，查看罚单详情</span>
                  </div>
                  <div style="height: 20px;"></div>
                  <div>
                    <div>
                      <div v-if="item.rsxDetailList != null">
                        <div class="weekly-punish-div-group" >
                          <span class="weekly-punish-div-group-font">人身险</span>
                        </div>
                        <div v-for="item1 in item.rsxDetailList" @click="punDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                          <div style="margin-left: 15px">
                            <img src="@/assets/image/weekly/dj/yuan-zi.png" style="margin-top: 5px;width: 15px; flex: 0 0 11.5px; height: 15px" alt=""/>
                          </div>
                          <div style="width: 284px;font-size: 16px;font-weight: 400;line-height: 24px;font-family: 思源黑体;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                          </div>
                        </div>
                      </div>

                      <div v-if="item.zjDetailList != null">

                        <div class="weekly-punish-div-group" >
                          <span class="weekly-punish-div-group-font">银邮</span>
                        </div>
                        <div v-for="item1 in item.zjDetailList" @click="punDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                          <div style="margin-left: 15px">
                            <img src="@/assets/image/weekly/dj/yuan-zi.png" style="margin-top: 5px;width: 15px; flex: 0 0 11.5px; height: 15px" alt=""/>
                          </div>
                          <div style="width: 284px;font-size: 16px;font-weight: 400;line-height: 24px;font-family: 思源黑体;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="index != punishList.length - 1 " style="height: 5px"></div>
                    <div v-if="index == punishList.length - 1 && !otherFlag" style="height: 5px"></div>
                    <!--<div v-if="index == punishList.length - 1 && otherFlag" style="text-align: center;height: 40px;cursor: pointer;" @click="moreOther">-->
                      <!--<span class="weekly-font-more">加载更多</span>-->
                    <!--</div>-->
                  </div>
                </div>
                <div style="height: 1px"></div>
              </div>

            </div>

            <!--人行模块-->
            <div style="" id="人行" v-if="weeklyInfo.bankTableDetailRespVO.punNum != 0">
              <div  class="weekly-div-background-s" >
                <div style="text-align: center">
                  <div style="height: 8px"></div>
                  <span class="weekly-font-other-title"> 人民银行及其分行 </span>
                </div>
              </div>
              <div style="width: 329px;background: linear-gradient(#F3E6D8,#F4E0BC);margin-left: 13px;border-radius: 0px 0px 20px 20px;">
                <div  class="weekly-div-background-z" >
                  <div style="padding-top: 10px;margin-left: 15px;margin-bottom: -10px">
                    <span class="weekly-font-table-beizhu">点击处罚信息，查看罚单详情</span>
                  </div>
                  <div style="height: 15px"></div>
                  <div>
                    <div v-for="item1 in weeklyInfo.bankDetailRespVO.bankDetailList" @click="punishBankDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                      <div style="margin-left: 15px">
                        <img src="@/assets/image/weekly/dj/yuan-zi.png" style="margin-top: 5px;width: 15px; flex: 0 0 11.5px; height: 15px" alt=""/>
                      </div>
                      <div style="width: 284px;font-size: 16px;font-weight: 400;line-height: 24px;font-family: 思源黑体;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                      </div>
                    </div>

                    <div style="height: 5px"></div>
                  </div>
                </div>
                <div style="height: 1px"></div>
              </div>
            </div>


          </div>
          <div style="width: 351px;height: 15px;background-color: rgba(255, 255, 255, 0.95);margin-left: 12px;margin-top: -36px;border-radius: 0px 0px 10px 10px"></div>
        </div>
        <!--年度处罚分析-->
        <div v-show="clickFlag">
          <div>
            <span :style="this.weeklyDate == 20240826 ? 'top:465px' : 'top:265px'" class="weekly-tag-left-font1">{{year}}年处罚分析</span>
            <span :style="this.weeklyDate == 20240826 ? 'top:465px' : 'top:265px'" @click="filtrate(false)"  class="weekly-tag-left-font">本周处罚详情</span>
            <img v-if="this.weeklyDate == 20240826" style="position: absolute;left: 4px;top: 453px;width: 361px;height: 55px" src="@/assets/image/weekly/dj/left-tab-s.png" alt=""/>
            <img  v-else style="position: absolute;left: 4px;top: 253px;width: 361px;height: 55px" src="@/assets/image/weekly/dj/left-tab-s.png" alt=""/>
          </div>
          <div :style="this.weeklyDate == 20240826 ? 'margin-top:455px' : 'margin-top:255px'" class="weekly-tag-right-background-x">
            <div v-show="weeklyInfo.haveRsxFlag">
              <div style="height: 10px"></div>
              <div style="margin-left: 4px" class="weekly-punish-div-fxbg">
                <div style="height: 10px"></div>
                <span class="weekly-punish-title"> 人身险公司处罚分析</span>
              </div>
              <!--总体处罚情况-->
              <div style="margin-left: 15px">
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 总体处罚情况 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>
                <div style="width: 300px;margin-left: 20px">
                  <span class="weekly-font-ensemble">{{year}}年截至{{weeklyInfo.month}}月{{weeklyInfo.day}}日，银保监系统共向人身险公司开出罚单<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.rsxReport.sumPun}}</span>张，其中机构罚单<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.rsxReport.comPun}}</span>张，个人罚单<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.rsxReport.perPun}}</span>张；</span>
                  <span class="weekly-font-ensemble">处罚金额<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.rsxReport.sumMoney}}</span>万元，机构处罚金额<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.rsxReport.comMoney}}</span>万元，个人处罚金额<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.rsxReport.perMoney}}</span>万元。</span>
                </div>
              </div>
              <div class="weekly-punish-div-background-z"></div>
              <!--被罚人员分析-->
              <div>
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 被罚人员分析 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>
                <div style="margin-left: 15px">
                  <div>
                    <div style="width: 310px;margin-left: 20px">
                      <span class="weekly-font-ensemble" style="font-weight: 700;color: #3B3B4C">共<span style="color: #C6281C">{{weeklyInfo.rsxReport.perPunNum}}</span>张罚单涉及或关联到个人处罚。</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;margin-top: 8px;display: flex;align-items: center">
                      <img src="@/assets/image/weekly/dj/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                      <span class="weekly-font-person">高管层{{weeklyInfo.rsxReport.gzPun}}人，罚款{{weeklyInfo.rsxReport.gzMoney}}万元；</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                      <img src="@/assets/image/weekly/dj/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                      <span class="weekly-font-person">部门级别{{weeklyInfo.rsxReport.bmPun}}人，罚款{{weeklyInfo.rsxReport.bmMoney}}万元；</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                      <img src="@/assets/image/weekly/dj/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                      <span class="weekly-font-person">经办人员{{weeklyInfo.rsxReport.jbryPun}}人，罚款 {{weeklyInfo.rsxReport.jbryMoney}}万元。</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                      <img src="@/assets/image/weekly/dj/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                      <span class="weekly-font-person">其他人员{{weeklyInfo.rsxReport.qtPun}}人，罚款 {{weeklyInfo.rsxReport.qtMoney}}万元。</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                      <span class="weekly-font-person-beizhu">( 其他人员: 指其他职务或者未披露职务的人员)</span>
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 20px;align-items: center">
                    <el-progress :show-text = "true" :format="setItemText('rsx_gg_p')" :text-inside="true" :stroke-width="16" color="#C8A35D" style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.rsxProgressVO.ggPerson"></el-progress>
                    <div class="weekly-punish-div-person">
                      <div style="height: 2px"></div>
                      <span class="weekly-punish-div-person-font">高管层 </span>
                    </div>
                    <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('rsx_gg_m')"  :text-inside="true" color="#C6281C" :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.rsxProgressVO.ggMoney"></el-progress>
                  </div>
                  <div style="display: flex;align-items: center;margin-top: 10px;">
                    <el-progress :show-text = "true" :text-inside="true" :format="setItemText('rsx_bm_p')"  :stroke-width="16" color="#C8A35D"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.rsxProgressVO.bmPerson"></el-progress>
                    <div class="weekly-punish-div-person">
                      <div style="height: 2px"></div>
                      <span class="weekly-punish-div-person-font">部门级别 </span>
                    </div>
                    <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('rsx_bm_m')"  :text-inside="true" color="#C6281C"   :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.rsxProgressVO.bmMoney"></el-progress>
                  </div>
                  <div style="display: flex;align-items: center;margin-top: 10px;">
                    <el-progress :show-text = "true" :text-inside="true" :format="setItemText('rsx_jb_p')"   :stroke-width="16" color="#C8A35D"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.rsxProgressVO.jbPerson"></el-progress>
                    <div class="weekly-punish-div-person">
                      <div style="height: 2px"></div>
                      <span class="weekly-punish-div-person-font">经办人员 </span>
                    </div>
                    <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('rsx_jb_m')"  :text-inside="true" color="#C6281C"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.rsxProgressVO.jbMoney"></el-progress>
                  </div>
                  <div style="display: flex;align-items: center;margin-top: 10px;">
                    <el-progress :show-text = "true" :text-inside="true"  :format="setItemText('rsx_qt_p')"  :stroke-width="16" color="#C8A35D"   style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.rsxProgressVO.qtPerson"></el-progress>
                    <div class="weekly-punish-div-person">
                      <div style="height: 2px"></div>
                      <span class="weekly-punish-div-person-font">其他 </span>
                    </div>
                    <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('rsx_qt_m')"  :text-inside="true" color="#C6281C"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.rsxProgressVO.qtMoney"></el-progress>
                  </div>
                </div>

              </div>
              <div class="weekly-punish-div-background-z"></div>
              <!--处罚机构分析-->
              <div>
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 处罚机构分析 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>


                <div style="width: 310px;margin-left: 30px">
                  <span class="weekly-font-ensemble">重点关注机构中{{weeklyInfo.rsxSubVO.maxMoneyName}}处罚金额最高，累计高达<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.rsxSubVO.maxMoney}}</span>万；{{weeklyInfo.rsxSubVO.maxPunName}}罚单数量最多，累计罚单<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.rsxSubVO.maxPunNumber}}</span>张。</span>
                </div>

                <div id="punishjg2" style="margin-left: 32px;height: 230px;width: 300px;margin-top: 20px"></div>
              </div>
              <!--亿元保费分析-->
              <div v-if="weeklyInfo.config.yybfFlag && weeklyInfo.rsxYySubVO.showFlag">
                <div style="margin-left: 11px" class="weekly-punish-div-background-x"></div>
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 亿元保费处罚分析 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>
                <div style="width: 310px;margin-left: 30px">
                  <span class="weekly-font-ensemble">{{weeklyInfo.month == 1 ? year - 1 : year}}年截至{{weeklyInfo.month == 1 ? 12 : weeklyInfo.month - 1}}月，重点关注机构中{{weeklyInfo.rsxYySubVO.maxPunName}}亿元保费罚单数<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.rsxYySubVO.maxPunNumber}}</span>张，排名第一；{{weeklyInfo.rsxYySubVO.maxMoneyName}}亿元保费罚款总额<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.rsxYySubVO.maxMoney}}</span>万，排名第一；</span>
                </div>
                <div style="width: 330px;margin-left: 30px;margin-top: 5px">
                  <span class="weekly-font-person-beizhu">注：原保险保费收入为官网披露的上一年度同期数据。</span>
                </div>

                <div id="rsxyy" style="margin-left: 27px;height: 230px;width: 320px;margin-top: 20px"></div>
              </div>
              <div style="margin-left: 11px" class="weekly-punish-div-background-x"></div>
              <!--处罚事由排行-->
              <div>
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 处罚事由排行 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>
                <div style="width: 310px;margin-left: 30px">
                   <span class="weekly-font-ensemble">
                      人身险公司处罚频次前三的事由包括：
                      {{weeklyInfo.rsxReasonVO.nameList[weeklyInfo.rsxReasonVO.nameList.length-1]}}共处罚<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.rsxReasonVO.reasonNumList[weeklyInfo.rsxReasonVO.reasonNumList.length - 1]}}</span>次；
                      {{weeklyInfo.rsxReasonVO.nameList[weeklyInfo.rsxReasonVO.nameList.length-2]}}共处罚<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.rsxReasonVO.reasonNumList[weeklyInfo.rsxReasonVO.reasonNumList.length - 2]}}</span>次；
                      {{weeklyInfo.rsxReasonVO.nameList[weeklyInfo.rsxReasonVO.nameList.length-3]}}共处罚<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.rsxReasonVO.reasonNumList[weeklyInfo.rsxReasonVO.reasonNumList.length - 3]}}</span>次。
                   </span>
                </div>
                <div id="reason1" :style="{'height': weeklyInfo.rsxReasonVO.maxHeight + 'px','width': '330px','margin-top': '-30px','margin-left': '12px','margin-bottom':'-20px'}"></div>
              </div>
            </div>
          </div>
          <div style="width: 351px;height: 15px;background-color: rgb(246, 242, 233);margin-left: 12px;margin-top: -20px;border-radius: 0px 0px 10px 10px"></div>



          <div class="weekly-tag-right-background-zj">
            <div v-show="weeklyInfo.haveZjFlag">
              <div style="height: 30px"></div>
              <div style="margin-left: 0px;" class="weekly-punish-div-fxbg">
                <div style="height: 10px"></div>
                <span class="weekly-punish-title"> 银邮兼业代理公司处罚分析</span>
              </div>

              <!--总体处罚情况-->
              <div style="margin-left: 5px">
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 总体处罚情况 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>
                <div style="width: 300px;margin-left: 20px">
                  <span class="weekly-font-ensemble">{{year}}年截至{{weeklyInfo.month}}月{{weeklyInfo.day}}日，银保监系统共向银邮兼业代理公司开出罚单<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.zjReport.sumPun}}</span>张，其中机构罚单<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.zjReport.comPun}}</span>张，个人罚单<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.zjReport.perPun}}</span>张；</span>
                  <span class="weekly-font-ensemble">处罚金额<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.zjReport.sumMoney}}</span>万元，机构处罚金额<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.zjReport.comMoney}}</span>万元，个人处罚金额<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.zjReport.perMoney}}</span>万元。</span>
                </div>
              </div>
              <div style="margin-left: 8px" class="weekly-punish-div-background-z"></div>

              <!--被罚人员分析-->
              <div>
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 被罚人员分析 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>
                <div style="margin-left: 5px">
                  <div>
                    <div style="width: 310px;margin-left: 20px">
                      <span class="weekly-font-ensemble" style="font-weight: 700;color: #3B3B4C">共<span style="color: #C6281C">{{weeklyInfo.zjReport.perPunNum}}</span>张罚单涉及或关联到个人处罚。</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;margin-top: 8px;display: flex;align-items: center">
                      <img src="@/assets/image/weekly/dj/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                      <span class="weekly-font-person">高管层{{weeklyInfo.zjReport.gzPun}}人，罚款{{weeklyInfo.zjReport.gzMoney}}万元；</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                      <img src="@/assets/image/weekly/dj/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                      <span class="weekly-font-person">部门级别{{weeklyInfo.zjReport.bmPun}}人，罚款{{weeklyInfo.zjReport.bmMoney}}万元；</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                      <img src="@/assets/image/weekly/dj/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                      <span class="weekly-font-person">经办人员{{weeklyInfo.zjReport.jbryPun}}人，罚款 {{weeklyInfo.zjReport.jbryMoney}}万元。</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                      <img src="@/assets/image/weekly/dj/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                      <span class="weekly-font-person">其他人员{{weeklyInfo.zjReport.qtPun}}人，罚款 {{weeklyInfo.zjReport.qtMoney}}万元。</span>
                    </div>
                    <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                      <span class="weekly-font-person-beizhu">( 其他人员: 指其他职务或者未披露职务的人员)</span>
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 20px;align-items: center">
                    <el-progress :show-text = "true" :format="setItemText('zj_gg_p')" :text-inside="true" :stroke-width="16" color="#C8A35D" style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.zjProgressVO.ggPerson"></el-progress>
                    <div class="weekly-punish-div-person">
                      <div style="height: 2px"></div>
                      <span class="weekly-punish-div-person-font">高管层 </span>
                    </div>
                    <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('zj_gg_m')"  :text-inside="true" color="#C6281C" :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.zjProgressVO.ggMoney"></el-progress>
                  </div>
                  <div style="display: flex;align-items: center;margin-top: 10px;">
                    <el-progress :show-text = "true" :text-inside="true" :format="setItemText('zj_bm_p')"  :stroke-width="16" color="#C8A35D"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.zjProgressVO.bmPerson"></el-progress>
                    <div class="weekly-punish-div-person">
                      <div style="height: 2px"></div>
                      <span class="weekly-punish-div-person-font">部门级别 </span>
                    </div>
                    <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('zj_bm_m')"  :text-inside="true" color="#C6281C"   :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.zjProgressVO.bmMoney"></el-progress>
                  </div>
                  <div style="display: flex;align-items: center;margin-top: 10px;">
                    <el-progress :show-text = "true" :text-inside="true" :format="setItemText('zj_jb_p')"   :stroke-width="16" color="#C8A35D"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.zjProgressVO.jbPerson"></el-progress>
                    <div class="weekly-punish-div-person">
                      <div style="height: 2px"></div>
                      <span class="weekly-punish-div-person-font">经办人员 </span>
                    </div>
                    <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('zj_jb_m')"  :text-inside="true" color="#C6281C"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.zjProgressVO.jbMoney"></el-progress>
                  </div>
                  <div style="display: flex;align-items: center;margin-top: 10px;">
                    <el-progress :show-text = "true" :text-inside="true"  :format="setItemText('zj_qt_p')"  :stroke-width="16" color="#C8A35D"   style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="weeklyInfo.zjProgressVO.qtPerson"></el-progress>
                    <div class="weekly-punish-div-person">
                      <div style="height: 2px"></div>
                      <span class="weekly-punish-div-person-font">其他 </span>
                    </div>
                    <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText('zj_qt_m')"  :text-inside="true" color="#C6281C"  :stroke-width="16"  style="width: 100px;" :percentage="weeklyInfo.zjProgressVO.qtMoney"></el-progress>
                  </div>
                </div>

              </div>
              <div style="margin-left: 8px" class="weekly-punish-div-background-z"></div>
              <!--处罚机构分析-->
              <div>
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 处罚机构分析 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>


                <div style="width: 310px;margin-left: 20px">
                  <span class="weekly-font-ensemble">重点关注机构中{{weeklyInfo.zjSubVO.maxMoneyName}}处罚金额最高，累计高达<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.zjSubVO.maxMoney}}</span>万；{{weeklyInfo.zjSubVO.maxPunName}}罚单数量最多，累计罚单<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.zjSubVO.maxPunNumber}}</span>张。</span>
                </div>

                <div id="punishjg1" style="margin-left: 17px;height: 230px;width: 300px;margin-top: 20px"></div>
              </div>

              <div v-show="weeklyInfo.zjReasonVO.nameList[weeklyInfo.zjReasonVO.nameList.length-1]" class="weekly-punish-div-background-x"></div>
              <!--处罚事由排行-->
              <div v-show="weeklyInfo.zjReasonVO.nameList[weeklyInfo.zjReasonVO.nameList.length-1]">
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 处罚事由排行 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>
                <div style="width: 310px;margin-left: 20px">
                   <span class="weekly-font-ensemble">
                      银邮兼业代理公司处罚频次前三的事由包括：
                      {{weeklyInfo.zjReasonVO.nameList[weeklyInfo.zjReasonVO.nameList.length-1]}}共处罚<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.zjReasonVO.reasonNumList[weeklyInfo.zjReasonVO.reasonNumList.length - 1]}}</span>次；
                      {{weeklyInfo.zjReasonVO.nameList[weeklyInfo.zjReasonVO.nameList.length-2]}}共处罚<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.zjReasonVO.reasonNumList[weeklyInfo.zjReasonVO.reasonNumList.length - 2]}}</span>次；
                      {{weeklyInfo.zjReasonVO.nameList[weeklyInfo.zjReasonVO.nameList.length-3]}}共处罚<span style="font-weight: 700;color: #C6281C">{{weeklyInfo.zjReasonVO.reasonNumList[weeklyInfo.zjReasonVO.reasonNumList.length - 3]}}</span>次。
                   </span>
                </div>
                <div id="reason2" :style="{'height': weeklyInfo.zjReasonVO.maxHeight + 'px','width': '330px','margin-top': '-30px','margin-left': '12px','margin-bottom':'-20px'}"></div>
                <div style="height: 15px"></div>
              </div>
            </div>
          </div>
          <div class="weekly-tag-right-background-bk">
            <div v-show="weeklyInfo.haveZjFlag">
              <div style="height: 30px"></div>
              <div style="margin-left: 0px;" class="weekly-punish-div-fxbg">
                <div style="height: 10px"></div>
                <span class="weekly-punish-title"> 人民银行及分行处罚分析</span>
              </div>

              <!--总体处罚情况-->
              <div style="margin-left: 5px">
                <div style="text-align: center">
                  <img style="margin-right: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                  <span class="weekly-font-title"> 总体处罚情况 </span>
                  <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                </div>
                <div style="width: 300px;margin-left: 20px">
                  <span class="weekly-font-ensemble">{{year}}年截至{{weeklyInfo.month}}月{{weeklyInfo.day}}日，人行系统共向保险公司开出罚单<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.reportBank.sumPun}}</span>张，其中机构罚单<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.reportBank.comPun}}</span>张，个人罚单<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.reportBank.perPun}}</span>张；</span>
                  <span class="weekly-font-ensemble">处罚金额<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.reportBank.sumMoney}}</span>万元，机构处罚金额<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.reportBank.comMoney}}</span>万元，个人处罚金额<span style="color: #C6281C;font-weight: 700">{{weeklyInfo.reportBank.perMoney}}</span>万元。</span>
                </div>
              </div>
              <div style="margin-left: 8px" class="weekly-punish-div-background-z"></div>

              <div style="text-align: center">
                <img style="margin-right: 15px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
                <span class="weekly-font-title" style="font-size: 16px"> 被罚机构类型情况 </span>
                <img style="transform:rotatey(180deg);margin-left: 15px;" src="@/assets/image/weekly/dj/biaoti.png" alt=""/>
              </div>

              <div class="regional-table-bank">
                <div class="regional-table-bank-bankground">
                  <el-table
                      :data="weeklyInfo.orgList"
                      border
                      :row-style="{height: 30+'px',background:'rgb(232, 207, 164, 0.2)'}"
                      :cell-style="{padding:0+'px','text-align':'center',background:'rgb(232, 207, 164, 0.2)','font-size': '14px','font-family': '思源黑体','font-weight': '400',color: 'rgb(152, 120, 61)','border-color':'rgb(228, 198, 144)'}"
                      :header-cell-style="{height: 30+'px',padding:0+'px',background:'rgb(232, 207, 164)','text-align':'center','font-size': '14px','font-family': '思源黑体','font-weight': '700',color: 'rgb(149, 118, 58)','border-color':'rgb(228, 198, 144)'}"
                      class="weekly-el-table-bk"
                  >
                    <el-table-column prop="orgName" label="机构类型" width="108">
                      <template slot-scope="scope">
                        <div style="color:rgb(0, 0, 0);">{{ scope.row.orgName }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="punNum" label="罚单数（张）" width="103">
                      <template slot="header">
                        <div>
                          罚单数<br>
                          <span style="">（张）</span>
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <div  style="color:rgb(0, 0, 0);">{{ scope.row.punNum }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="punMoney"  label="罚款金额   （万元）" width="108">
                      <template slot="header">
                        <div>
                          罚款金额<br>
                          <span style="">（万元）</span>
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <div style="color:rgb(0, 0, 0);">{{ scope.row.punMoney }}</div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div style="height: 40px"></div>
        <span  style="position: absolute;bottom: 10px;z-index:-998;left:72px;color: #FFFFFF;font-size: 14px">注: 统计数据期间以监管披露时间为准</span>
      </div>
    </div>
  </div>
</template>
<script>
  import * as echarts from 'echarts';
  import { getWeeklyDetailApi,getYearDetailApi,getWeekDetailApi} from "@/api/weeklyApi";
  export default {
    name: 'report',
    data() {
      return {
        clickFlag:false,
        otherFlag:false,
        weeklyDate: undefined,
        showPopover:false,
        year: "",
        groupId: "",
        weeklyInfo: {
          rsxReport: {},
          zjReport: {},
          ccxReport: {},
          tableDetailRespVO : {},
          bankTableDetailRespVO:{},
          bankDetailRespVO:{},
          reportBank:{},
          config: {},
          rsxSubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          rsxYySubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          zjSubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          ccxSubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          ccxYySubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          rsxReasonVO: {
            nameList: [],
            reasonNumList: [],
            maxLenList: [],
          },
          ccxReasonVO: {
            nameList: [],
            reasonNumList: [],
            maxLenList: [],
          },
          zjReasonVO: {
            nameList: [],
            reasonNumList: [],
            maxLenList: [],
          },
          rsxProgressVO: {},
          zjProgressVO: {},
          ccxProgressVO: {},
          noDataVO: {},
        },
        punishList: [],
      };
    },
    mounted() {
      var date = this.$route.query.date;
      if(date < 20240826){
        this.$router.push({ path: "/weekly/dj/report/v1", query: this.$route.query });
      }
      this.groupId = this.genID()
      this.initWeeklyDatas();
    },
    methods: {
      genID(){
        return Number(Math.random().toString().substr(3,3) + Date.now()).toString();
      },
      //锚点跳转
      goAnchor(selector,value) {/*参数selector是id选择器（#anchor14）*/
        if(value != 0){
          document.querySelector(selector).scrollIntoView({
            behavior: "smooth"
          });
        }
      },
      setItemText(value) {
        if(value == "rsx_gg_p"){
          return () => {
            return this.weeklyInfo.rsxReport.gzPun + '人'
          }
        }
        if(value == "rsx_gg_m"){
          return () => {
            return this.weeklyInfo.rsxReport.gzMoney + '万元'
          }
        }
        if(value == "rsx_bm_p"){
          return () => {
            return this.weeklyInfo.rsxReport.bmPun + '人'
          }
        }
        if(value == "rsx_bm_m"){
          return () => {
            return this.weeklyInfo.rsxReport.bmMoney + '万元'
          }
        }
        if(value == "rsx_jb_p"){
          return () => {
            return this.weeklyInfo.rsxReport.jbryPun + '人'
          }
        }
        if(value == "rsx_jb_m"){
          return () => {
            return this.weeklyInfo.rsxReport.jbryMoney + '万元'
          }
        }
        if(value == "rsx_qt_p"){
          return () => {
            return this.weeklyInfo.rsxReport.qtPun + '人'
          }
        }
        if(value == "rsx_qt_m"){
          return () => {
            return this.weeklyInfo.rsxReport.qtMoney + '万元'
          }
        }
        if(value == "zj_gg_p"){
          return () => {
            return this.weeklyInfo.zjReport.gzPun + '人'
          }
        }
        if(value == "zj_gg_m"){
          return () => {
            return this.weeklyInfo.zjReport.gzMoney + '万元'
          }
        }
        if(value == "zj_bm_p"){
          return () => {
            return this.weeklyInfo.zjReport.bmPun + '人'
          }
        }
        if(value == "zj_bm_m"){
          return () => {
            return this.weeklyInfo.zjReport.bmMoney + '万元'
          }
        }
        if(value == "zj_jb_p"){
          return () => {
            return this.weeklyInfo.zjReport.jbryPun + '人'
          }
        }
        if(value == "zj_jb_m"){
          return () => {
            return this.weeklyInfo.zjReport.jbryMoney + '万元'
          }
        }
        if(value == "zj_qt_p"){
          return () => {
            return this.weeklyInfo.zjReport.qtPun + '人'
          }
        }
        if(value == "zj_qt_m"){
          return () => {
            return this.weeklyInfo.zjReport.qtMoney + '万元'
          }
        }
        if(value == "ccx_gg_p"){
          return () => {
            return this.weeklyInfo.ccxReport.gzPun + '人'
          }
        }
        if(value == "ccx_gg_m"){
          return () => {
            return this.weeklyInfo.ccxReport.gzMoney + '万元'
          }
        }
        if(value == "ccx_bm_p"){
          return () => {
            return this.weeklyInfo.ccxReport.bmPun + '人'
          }
        }
        if(value == "ccx_bm_m"){
          return () => {
            return this.weeklyInfo.ccxReport.bmMoney + '万元'
          }
        }
        if(value == "ccx_jb_p"){
          return () => {
            return this.weeklyInfo.ccxReport.jbryPun + '人'
          }
        }
        if(value == "ccx_jb_m"){
          return () => {
            return this.weeklyInfo.ccxReport.jbryMoney + '万元'
          }
        }
        if(value == "ccx_qt_p"){
          return () => {
            return this.weeklyInfo.ccxReport.qtPun + '人'
          }
        }
        if(value == "ccx_qt_m"){
          return () => {
            return this.weeklyInfo.ccxReport.qtMoney + '万元'
          }
        }
      },
      initWeeklyDatas(){
        var key = this.$route.query.key;
        var date = this.$route.query.date;
        var userId = this.$route.query.UserId;
        this.weeklyDate = date;
        var dataStatus = this.$route.query.dataStatus;
        getWeeklyDetailApi({
          params: {
            key: key,
            date: date,
            userId: userId,
            groupId: this.groupId,
            dataStatus: dataStatus,
          },
        }).then((ret) => {
          //处理下划线以及字体颜色

          if(ret.data.data.bankTableDetailRespVO.punNum != 0){
            ret.data.data.bankDetailRespVO.bankDetailList.forEach(item =>{
              item.detailName = item.detailName + "<span style=\"\">";
              item.detailName = item.detailName.replaceAll("@*","")
              item.detailName = item.detailName.replaceAll("【","")
              item.detailName = item.detailName.replaceAll("】","")
              item.detailName = item.detailName.replaceAll("*@","</span>")
              item.detailName = item.detailName.replaceAll("@#","<span style=\"color: #C00003;font-weight: 700\">")
              item.detailName = item.detailName.replaceAll("#@","</span>")
              item.detailName = item.detailName + "</span>"
              item.detailName = item.detailName.replaceAll("(","<div><span style=\"font-size: 14px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
              item.detailName = item.detailName.replaceAll(")","</span></div>")
            })
          }
          // if(ret.data.data.haveSpFlag){
          //   ret.data.data.splList.forEach(item =>{
          //     item.detailName = item.detailName + "<span style=\"\">";
          //     item.detailName = item.detailName.replaceAll("@*","")
          //     item.detailName = item.detailName.replaceAll("【","")
          //     item.detailName = item.detailName.replaceAll("】","")
          //     item.detailName = item.detailName.replaceAll("*@","</span>")
          //     item.detailName = item.detailName.replaceAll("@#","<span style=\"color: #C00003;font-weight: 700\">")
          //     item.detailName = item.detailName.replaceAll("#@","</span>")
          //     item.detailName = item.detailName + "</span>"
          //     item.detailName = item.detailName.replaceAll("(","<div><span style=\"font-size: 14px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
          //     item.detailName = item.detailName.replaceAll(")","</span></div>")
          //   })
          // }
          ret.data.data.detailRespList.forEach(item =>{
            if(item.rsxDetailList != null){
              item.rsxDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","")
                item1.detailName = item1.detailName.replaceAll("【","")
                item1.detailName = item1.detailName.replaceAll("】","")
                item1.detailName = item1.detailName.replaceAll("*@","")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #C00003;font-weight: 700\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>"
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 14px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
            if(item.rsxFiveDetailList != null){
              item.rsxFiveDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","")
                item1.detailName = item1.detailName.replaceAll("【","")
                item1.detailName = item1.detailName.replaceAll("】","")
                item1.detailName = item1.detailName.replaceAll("*@","")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #C00003;font-weight: 700\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>";
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 14px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
            if(item.zjDetailList != null){
              item.zjDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","")
                item1.detailName = item1.detailName.replaceAll("【","")
                item1.detailName = item1.detailName.replaceAll("】","")
                item1.detailName = item1.detailName.replaceAll("*@","")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #C00003;font-weight: 700\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>";
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 14px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
            if(item.zjFiveDetailList != null){
              item.zjFiveDetailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","")
                item1.detailName = item1.detailName.replaceAll("【","")
                item1.detailName = item1.detailName.replaceAll("】","")
                item1.detailName = item1.detailName.replaceAll("*@","")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #C00003;font-weight: 700\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>";
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 14px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 400;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
              })
            }
          })
          this.punishList = ret.data.data.detailRespList;
          this.weeklyInfo =  ret.data.data;
          this.year = this.weeklyInfo.weekly.year;
          // let other = this.punishList[this.punishList.length-1];
          // if(other != undefined){
          //   if(other.subName == '其他地区'){
          //     if(other.detailList.length > 5){
          //       this.otherFlag = true
          //     }
          //   }
          // }
          //画图表
          this.$nextTick(() => {
            if(ret.data.data.haveRsxFlag){
              this.getEcharsReasonRsx(); //人身险处罚事由
              this.getEcharsRsx(); //人身险处罚机构
              if(ret.data.data.config.yybfFlag && ret.data.data.rsxYySubVO != null && ret.data.data.rsxYySubVO.showFlag){
                this.getEcharsYyRsx();//人身险亿元保费
              }
            }
            if(ret.data.data.haveZjFlag){
              this.getEcharsReasonZj();//中介处罚事由
              this.getEcharsZj(); //中介处罚机构
            }
          });
        });
      },
      // tag点击事件
      filtrate(value) {
        if(value){
          getYearDetailApi({
            params: {
              key: this.$route.query.key,
              date: this.$route.query.date,
              groupId: this.groupId,
              userId: this.$route.query.UserId,
            },
          })
        }else{
          getWeekDetailApi({
            params: {
              key: this.$route.query.key,
              date: this.$route.query.date,
              groupId: this.groupId,
              userId: this.$route.query.UserId,
            },
          })
        }
        this.clickFlag = value
      },
      // 加载更多点击事件
      moreOther() {
        this.otherFlag = false;
      },
      punishDetail(id) {
        if(id.assList.length == 1) {
          let text = this.$router.resolve({ name: "weeklyDetail", query: {
              id : id.assList[0].punId ,
              key:this.$route.query.key,
              date:this.$route.query.date,
              userId: this.$route.query.UserId,
              groupId: this.groupId,
          } });
          window.open(text.href, '_blank')
        }else{
          window.open(id.otherLink, '_blank')
        }
      },
      punDetail(id) {
        let text = this.$router.resolve({ name: "weeklyDetail", query: {
            id : id ,
            key:this.$route.query.key,
            date:this.$route.query.date,
            userId: this.$route.query.UserId,
            groupId: this.groupId,
          } });
        window.open(text.href, '_blank')
      },
      punishSelectDetail(id) {
        let text = this.$router.resolve({ name: "weeklyDetail", query: {
            id : id ,
            key:this.$route.query.key,
            date:this.$route.query.date,
            userId: this.$route.query.UserId,
            groupId: this.groupId,
          } });
        window.open(text.href, '_blank')
      },
      punishOtherDetail(url) {
        window.open(url, '_blank')
      },
      punishBankDetail(id) {
        let text = this.$router.resolve({ name: "weeklyBankDetail", query: {
            id : id ,
            key:this.$route.query.key,
            date:this.$route.query.date,
            userId: this.$route.query.UserId,
            groupId: this.groupId,
          } });
        window.open(text.href, '_blank')
      },
      getEcharsRsx(){
        var myChart = echarts.init(document.getElementById('punishjg2'));
        const option = {
          legend: {

            x:'right',      //可设定图例在左、右、居中
            padding:[0,60,0,0],
            data: ['罚单(张)',  '罚金（万元）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.weeklyInfo.rsxSubVO.nameList,
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                formatter: function (value) {
                  return value.split("").join("\n")
                }
              }
            }
          ],
          yAxis: [
            {
              id: '罚单(张)',
              name: '(张)',
              nameTextStyle:{
                padding:[0, 0, 0, -30],
              },
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: "rgb(243, 231, 218)"
                }
              },
              min: 0,
              max: this.weeklyInfo.rsxSubVO.numberMax,
              interval: this.weeklyInfo.rsxSubVO.numberMax/5,
            },
            {
              id: '罚金（万元）',
              name: '万元',
              nameTextStyle:{
                padding:[0, 0, 0, 35],
              },
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: "rgb(243, 231, 218)"
                }
              },
              min: 0,
              max: this.weeklyInfo.rsxSubVO.moneyMax,
              interval: this.weeklyInfo.rsxSubVO.moneyMax/5,
            }
          ],
          dataZoom: [
            {
              type: "inside",  // 支持内部鼠标滚动平移
              start: 0,
              end: (20/this.weeklyInfo.rsxSubVO.nameList.length)*100,
              zoomOnMouseWheel: false,  // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
            }
          ],
          series: [
            {
              id: '罚单(张)',
              name: '罚单(张)',
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#C6281C'
                }
              },
              symbol: 'circle',
              lineStyle: {
                width: 1,
                type: 'solid' //'dotted'虚线 'solid'实线
              },
              data: this.weeklyInfo.rsxSubVO.numberList
            },

            {
              id: '罚金（万元）',
              name: '罚金（万元）',
              type: 'bar',
              barWidth: '60%',
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                    offset: 0,
                    color: 'rgba(200, 163, 93, 0.1)'
                  }, {
                    offset: 1,
                    color: 'rgba(200, 163, 93)'
                  }]),
                }
              },

              yAxisIndex: 1,
              data: this.weeklyInfo.rsxSubVO.moneyList
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsYyRsx(){
        var myChart = echarts.init(document.getElementById('rsxyy'));
        const option = {
          legend: {

            x:'right',      //可设定图例在左、右、居中
            padding:[0,60,0,0],
            data: ['罚单(张)',  '罚金（万元）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.weeklyInfo.rsxYySubVO.nameList,
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                formatter: function (value) {
                  return value.split("").join("\n")
                }
              }
            }
          ],
          yAxis: [
            {
              id: '罚单(张)',
              name: '(张)',
              nameTextStyle:{
                padding:[0, 0, 0, -30],
              },
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: "rgb(243, 231, 218)"
                }
              },
              min: 0,
              max: this.weeklyInfo.rsxYySubVO.numberMax,
              interval: this.weeklyInfo.rsxYySubVO.numberMax/5,
            },
            {
              id: '罚金（万元）',
              name: '万元',
              nameTextStyle:{
                padding:[0, 0, 0, 35],
              },
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: "rgb(243, 231, 218)"
                }
              },
              min: 0,
              max: this.weeklyInfo.rsxYySubVO.moneyMax,
              interval: this.weeklyInfo.rsxYySubVO.moneyMax/5,
            }
          ],
          dataZoom: [
            {
              type: "inside",  // 支持内部鼠标滚动平移
              start: 0,
              end: (20/this.weeklyInfo.rsxYySubVO.nameList.length)*100,
              zoomOnMouseWheel: false,  // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
            }
          ],
          series: [
            {
              id: '罚单(张)',
              name: '罚单(张)',
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#C6281C'
                }
              },
              symbol: 'circle',
              lineStyle: {
                width: 1,
                type: 'solid' //'dotted'虚线 'solid'实线
              },
              data: this.weeklyInfo.rsxYySubVO.numberList
            },

            {
              id: '罚金（万元）',
              name: '罚金（万元）',
              type: 'bar',
              barWidth: '60%',
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
// 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
// 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                    offset: 0,
                    color: 'rgba(200, 163, 93, 0.1)'
                  }, {
                    offset: 1,
                    color: 'rgba(200, 163, 93)'
                  }]),
                }
              },
              yAxisIndex: 1,
              data: this.weeklyInfo.rsxYySubVO.moneyList
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsZj(){
        var myChart = echarts.init(document.getElementById('punishjg1'));
        const option = {
          legend: {

            x:'right',      //可设定图例在左、右、居中
            padding:[0,50,0,0],
            data: ['罚单(张)',  '罚金（万元）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.weeklyInfo.zjSubVO.nameList,
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                formatter: function (value) {
                  return value.split("").join("\n")
                }
              }
            }
          ],
          yAxis: [
            {
              id: '罚单(张)',
              name: '(张)',
              nameTextStyle:{
                padding:[0, 0, 0, -30],
              },
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: "rgb(243, 231, 218)"
                }
              },
              min: 0,
              max: this.weeklyInfo.zjSubVO.numberMax,
              interval: this.weeklyInfo.zjSubVO.numberMax/5,
            },
            {
              id: '罚金（万元）',
              name: '万元',
              nameTextStyle:{
                padding:[0, 0, 0, 35],
              },
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: "rgb(243, 231, 218)"
                }
              },
              min: 0,
              max: this.weeklyInfo.zjSubVO.moneyMax,
              interval: this.weeklyInfo.zjSubVO.moneyMax/5,
            }
          ],
          dataZoom: [
            {
              type: "inside",  // 支持内部鼠标滚动平移
              start: 0,
              end: (20/this.weeklyInfo.zjSubVO.nameList.length)*100,
              zoomOnMouseWheel: false,  // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true,  // 鼠标移动能触发数据窗口平移
            }
          ],
          series: [
            {
              name: '罚单(张)',
              id: '罚单(张)',
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#C6281C'
                }
              },
              symbol: 'circle',
              lineStyle: {
                width: 1,
                type: 'solid' //'dotted'虚线 'solid'实线
              },
              data: this.weeklyInfo.zjSubVO.numberList
            },

            {
              name: '罚金（万元）',
              id: '罚金（万元）',
              type: 'bar',
              barWidth: '60%',
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
// 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
// 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                    offset: 0,
                    color: 'rgba(200, 163, 93, 0.1)'
                  }, {
                    offset: 1,
                    color: 'rgba(200, 163, 93)'
                  }]),
                }
              },
              yAxisIndex: 1,
              data: this.weeklyInfo.zjSubVO.moneyList
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsReasonRsx(){
        var myChart = echarts.init(document.getElementById('reason1'));
        const  option = {
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value',
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              splitLine: {
                show: false
              }
            }
          ],
          yAxis: [
            {
              type: 'category',
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              data: this.weeklyInfo.rsxReasonVO.nameList,
              axisLabel: {
                textStyle: {
                  fontSize: 12
                },
                formatter: (val) => {
                  let c = document.createElement("canvas");
                  const ctx = c.getContext("2d");
                  ctx.font = "16px normal"; // 设置画布内的字体，与设置的textStyle一致
                  const arr = val.split("");
                  arr.map((item) => ctx.measureText(item).width)
                          .reduce((pre, next, index) => {
                            const nLen = pre + next;
                            if (nLen > 180) {
                              arr[index - 1] += "\n";
                              return next;
                            } else {
                              return nLen;
                            }
                          });
                  c = null;
                  return arr.join("");
                }
              }
            }
          ],
          series: [
            {
              type: 'bar',
              label: {
                show: true,
                position: 'right'
              },

              barWidth: '60%',
              itemStyle: {
                normal: {
                  //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                  barBorderRadius: [0, 30, 30, 0],
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                    offset: 0,
                    color: 'rgba(200, 163, 93, 0.1)'
                  }, {
                    offset: 1,
                    color: 'rgba(200, 163, 93)'
                  }]),
                }
              },

              emphasis: {
                focus: 'series'
              },
              data: this.weeklyInfo.rsxReasonVO.reasonNumList
            },
            {
              type: 'bar',
              itemStyle: {
                normal: { color: 'rgba(214, 187, 138, 0.18)' }
              },
              barWidth: '60%',
              barGap: '-100%',
              data: this.weeklyInfo.rsxReasonVO.maxLenList,
              animation: false
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsReasonZj(){
        var myChart = echarts.init(document.getElementById('reason2'));
        const  option = {
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value',
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              splitLine: {
                show: false
              }
            }
          ],
          yAxis: [
            {
              type: 'category',
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              data: this.weeklyInfo.zjReasonVO.nameList,
              axisLabel: {
                textStyle: {
                  fontSize: 12
                },
                formatter: (val) => {
                  let c = document.createElement("canvas");
                  const ctx = c.getContext("2d");
                  ctx.font = "16px normal"; // 设置画布内的字体，与设置的textStyle一致
                  const arr = val.split("");
                  arr.map((item) => ctx.measureText(item).width)
                          .reduce((pre, next, index) => {
                            const nLen = pre + next;
                            if (nLen > 180) {
                              arr[index - 1] += "\n";
                              return next;
                            } else {
                              return nLen;
                            }
                          });
                  c = null;
                  return arr.join("");
                }
              }
            }
          ],
          series: [
            {
              type: 'bar',
              label: {
                show: true,
                position: 'right'
              },

              barWidth: '60%',
              itemStyle: {
                normal: {
                  //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                  barBorderRadius: [0, 30, 30, 0],
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                    offset: 0,
                    color: 'rgba(200, 163, 93, 0.1)'
                  }, {
                    offset: 1,
                    color: 'rgba(200, 163, 93)'
                  }]),
                }
              },

              emphasis: {
                focus: 'series'
              },
              data: this.weeklyInfo.zjReasonVO.reasonNumList
            },
            {
              type: 'bar',
              itemStyle: {
                normal: { color: 'rgba(214, 187, 138, 0.18)' }
              },
              barWidth: '60%',
              barGap: '-100%',
              data: this.weeklyInfo.zjReasonVO.maxLenList,
              animation: false
            }
          ]
        };
        option && myChart.setOption(option);
      },
    },
  };
</script>

<style lang="scss" >
  .weekly-dj-background {
    width: 375px;
    min-height: 580.15px;
    background: url("../../../../assets/image/weekly/dj/background.png") no-repeat  top 0px left 0px,linear-gradient(#F0DEBB, #E4CDA2);

    background-size: auto ;
    position: absolute;
    z-index: 999;
    overflow: hidden;
    .weekly-tags-left {
      width: 172.5px;
      height: 43.2px;
      position: absolute;
      left: 15px;
      top: 272px;
      background: linear-gradient(-70deg,#30DDF7  0%, #008AFF  100%);
      border-radius: 430px 0px 0px 430px;
    }
    .weekly-tags-right {
      width: 172.5px;
      height: 43.2px;
      position: absolute;
      left: 186.5px;
      top: 272px;
      background: linear-gradient(-70deg, #30DDF7  0%, #008AFF 100%);
      border-radius: 0px 430px 430px 0px;
    }

    .weekly-tag-left-font{
      position: absolute;
      width: 108px;
      height: 27px;
      left: 36px;
      top: 415px;
      opacity: 0.7;
      color: rgb(255, 255, 255);
      font-family: 思源黑体;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0px;
      cursor: pointer;
      z-index: 999;
    }

    .weekly-tag-right-font{
      position: absolute;
      width: 135px;
      height: 27px;
      left: 220px;
      top: 415px;
      opacity: 0.7;
      color: rgb(255, 255, 255);
      font-family: 思源黑体;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0px;
      cursor: pointer;
      z-index: 999;
    }

    .weekly-tag-left-font1{
      position: absolute;
      width: 165px;
      height: 27px;
      left: 188px;
      top: 415px;
      background: linear-gradient(180.00deg, rgb(215, 160, 80),rgb(199, 47, 32));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 思源黑体;
      font-size: 22px;
      font-weight: 900;
      line-height: 30px;
      letter-spacing: 0px;
      z-index: 999;
    }

    .weekly-tag-right-font1{
      position: absolute;
      width: 132px;
      height: 33px;
      left: 40px;
      right: 179px;
      top: 415px;
      background: linear-gradient(180.00deg, rgb(215, 160, 80),rgb(199, 47, 32));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 思源黑体;
      font-size: 22px;
      font-weight: 900;
      line-height: 30px;
      letter-spacing: 0px;
      z-index: 999;
    }

    .weekly-table-bankgroud {
      width: 329px;
      margin-left: 1px;
      margin-bottom: 0px;
      min-height: 300px;
      border-radius: 10px;
      background: linear-gradient(134.08deg, rgb(255, 251, 246) 7.016%,rgb(248, 223, 176) 100%);
    }

    .weekly-tag-background-x {
      background-image: url("../../../../assets/image/weekly/dj/tab-left-x.png");
      margin-left: 10px;
      margin-top: 385px;
      width: 361px;
      min-height: 500px;
      background-size: 100% 100%;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .weekly-tag-right-background-x {
      background-image: url("../../../../assets/image/weekly/dj/tab-x.png");
      margin-left: 10px;
      margin-top: 385px;
      width: 361px;
      min-height: 500px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .weekly-tag-right-background-zj {
      background: linear-gradient(180.00deg, rgba(255, 255, 255, 0) -18.523%,rgba(255, 255, 255, 0.88) 8.862%);
      border: 2px solid rgb(255, 255, 255);
      backdrop-filter: blur(30px);
      border-radius: 10px;
      margin-left: 12px;
      margin-top: 20px;
      width: 348px;
      min-height: 500px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .weekly-tag-right-background-bk {
      background: linear-gradient(180.00deg, rgba(255, 255, 255, 0) -18.523%,rgba(255, 255, 255, 0.88) 8.862%);
      border: 2px solid rgb(255, 255, 255);
      backdrop-filter: blur(30px);
      border-radius: 10px;
      margin-left: 12px;
      margin-top: 20px;
      width: 348px;
      min-height: 370px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .weekly-div-background-s {
      background-image: url("../../../../assets/image/weekly/dj/yj-s.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 329px;
      margin-left: 13px;
      margin-top: 15px;
      min-height: 40px;
    }

    .weekly-div-background-spc {
      background-image: url("../../../../assets/image/weekly/dj/yj-spc.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 329px;
      margin-left: 13px;
      margin-top: 10px;
      min-height: 92px;
    }
    .weekly-div-background-z {
      background: linear-gradient(134.08deg, rgb(255, 251, 246) 6.774%,rgb(248, 223, 176) 100%);
      border-radius: 0px 0px 20px 20px;
      width: 327px;
      margin-left: 1px;
      min-height: 50px;
    }
    .weekly-div-background-sp {
      background: linear-gradient(180.00deg, rgb(250, 245, 235) 0.056%,rgb(248, 223, 176) 103.333%);
      border-radius: 10px;
      width: 327px;
      margin-left: 1px;
      min-height: 50px;
    }
    .weekly-div-background-one {
      background-image: url("../../../../assets/image/weekly/di-one.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 105.5px;
    }
    .weekly-div-background-two {
      background-image: url("../../../../assets/image/weekly/di-two.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 128.8px;
    }
    .weekly-div-background-three {
      background-image: url("../../../../assets/image/weekly/di-three.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 152.1px;
    }
    .weekly-div-background-four {
      background-image: url("../../../../assets/image/weekly/di-four.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 349px;
      margin-left: 15px;
      min-height: 175.4px;
    }
    .weekly-font-title {
      width: 58.5px;
      height: 18.5px;
      color: rgb(152, 120, 61);
      font-family: 思源黑体;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0px;
      text-align: justify;
      line-height: 50px;
    }
    .weekly-font-other-title {
      width: 58.5px;
      height: 18.5px;
      color: rgb(255, 255, 255);
      font-family: 思源黑体;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
    }
    .weekly-font-no-title {
      width: 282px;
      height: 41.5px;
      font-size: 17px;
      font-family: 思源黑体;
      font-weight: bold;
      color: #15233B;
      line-height: 25px;
    }
    .weekly-font-no-title-three {
      width: 282px;
      height: 41.5px;
      font-size: 16px;
      font-family: 思源黑体;
      font-weight: bold;
      color: #15233B;
      line-height: 25px;
    }
    .weekly-font-no-title-four {
      width: 282px;
      height: 41.5px;
      font-size: 14.5px;
      font-family: 思源黑体;
      font-weight: bold;
      color: #15233B;
      line-height: 25px;
    }
    .weekly-font-no-content {
      width: 67.5px;
      height: 13px;
      font-size: 14px;
      font-family: 思源黑体;
      font-weight: 400;
      color: #15233B;
      line-height: 20px;
      margin-left: 20px;
    }
    .weekly-ensemble-title {
      width: 58.5px;
      height: 18.5px;
      font-size: 20px;
      font-family: 思源黑体;
      font-weight: bold;
      color: #15233B;
      line-height: 60px;
    }
    .weekly-punish-title {
      width: 242px;
      height: 21.5px;
      margin-left: 25px;
      color: rgb(255, 255, 255);
      font-family: 思源黑体;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
    }

    .weekly-punish-div-background {
      background-image: url("../../../../assets/image/weekly/lj-s.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 346px;
      min-height: 200px;
      margin-left: 15px;
    }
    .weekly-punish-div-background-z {
      background-image: url("../../../../assets/image/weekly/dj/line.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 333px;
      margin-left: 12px;
      margin-top: 20px;
      min-height: 5px;
    }

    .weekly-punish-div-background-x {
      background-image: url("../../../../assets/image/weekly/dj/line.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 333px;
      margin-left: 8px;
      min-height: 5px;
    }

    .weekly-punish-div-yybf {
      width: 345px;
      height: 415px;
      background: #FFFFFF;
      margin-left: 15px;
    }

    .weekly-div-background-date {
      background-image: url("../../../../assets/image/weekly/dj/date.png");
      margin-left: 258px;
      margin-top:14px;
      width: 117px;
      height: 39px;
      text-align: center;
    }

    .weekly-punish-date {
      color: rgb(255, 245, 225);
      font-family: 思源黑体;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0px;
      padding-left: 5px;
    }
    .weekly-font-ensemble {
      width: 300px;
      height: 111.5px;
      font-family: 思源黑体;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .weekly-font-table {
      width: 280px;
      height: 111.5px;
      font-size: 14px;
      font-family: 思源黑体;
      font-weight: 400;
      line-height: 20px;
    }
    .weekly-font-person {
      color: rgb(59, 59, 76);
      font-family: 思源黑体;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      width: 250px;
      height: 22.5px;
    }
    .weekly-font-more {
      width: 52px;
      height: 12px;
      font-size: 13px;
      font-family: 思源黑体;
      font-weight: 400;
      color: #1472FF;
    }
    .weekly-punish-div-fxbg {
      background-image: url("../../../../assets/image/weekly/dj/fxbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 351px;
      height: 40px;
      margin-left: 10px;
      margin-bottom: 15px;
    }
    .weekly-punish-div-person {
      background: linear-gradient(45.00deg, rgb(241, 232, 213) 0%,rgb(200, 163, 93) 38.931%,rgb(174, 135, 66) 80.002%);
      box-shadow: 0px 0px 8px rgb(200, 163, 93);
      border-radius: 4px;
      text-align: center;
      width: 90px;
      height: 30px;
    }
    .weekly-punish-div-person-font {
      width: auto;
      height: 13.5px;
      font-size: 14px;
      font-family: 思源黑体;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 26px;

    }
    .weekly-font-person-beizhu {
      font-size: 13px;
      font-family: 思源黑体;
      font-weight: 300;
      color: #666666;
    }

    .weekly-punish-div-group {
      background-image: url("../../../../assets/image/weekly/dj/fenlei.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 55px;
      height: 24px;
      margin-left: 15px;
      margin-bottom: 5px;
      text-align: center;
    }

    .weekly-punish-div-group-font {
      width: 27.5px;
      height: 13.5px;
      font-size: 14px;
      font-family: 思源黑体;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 20px;
    }

    .weekly-font-table-beizhu {
      font-size: 10px;
      font-family: 思源黑体;
      font-weight: 300;
      color: #666666;
    }

    .weekly-el-table-st {
      width: 321px;
      border-radius: 10px;
      border-color: rgb(228, 198, 144);
      background: transparent !important;
    }
    .weekly-el-table-bk {
      width: 321px;
      border-color: rgb(228, 198, 144);
      background: transparent !important;
    }
    .regional-table{
      width: 301px;
      margin-left: 14px;
      border-radius: 10px;

    }
    .regional-table-bankground{
      background-size: 100% 100%;
      width: 301px;
    }
    .regional-table-bank{
      width: 331px;
      margin-left: 14px;
      border-radius: 10px;

    }
    .regional-table-bank-bankground{
      background-size: 100% 100%;
      width: 331px;
    }
  }

  .el-main-weekly {
    width: 100%!important;
    height: 100%!important;
    background: #ffffff !important;
  }
  // 进度条样式
  .el-progress-bar__outer,
  .el-progress-bar__inner {
    border-radius: inherit;
    background-color: rgba(214, 187, 138, 0.18);
  }
  .el-table--border {
    border-bottom: 1px solid #EBEEF5;
  }
  .el-progress-bar__innerText {
    color: #433F5E;
    font-size: 11px;
    margin: 0 3px;
    transform: rotate(180deg);
  }

  .el-table--border::after, .el-table--group::after{
    width: 0;
  }
  .custom-popover {
    background-color: white!important; /* 背景色 */
    color: #98783D; /* 文字颜色 */
    border-color: #ddd; /* 边框颜色 */
    border-radius: 20px;
    /* 其他需要修改的样式 */
    .popper__arrow {
      font-size: 204px; /* 调整三角形大小 */
    }
  }
  .el-progress-bar__innerText1 {
    .el-progress-bar__innerText{
      color: #433F5E;
      font-size: 11px;
      margin: 0 3px;
      transform: rotate(0deg);
    }
  }

</style>
