<template>
  <div class="el-main-weekly-detail" >
    <div style="margin-left: 0px;width: 100%;height: 80%;">
      <div style="height: 40px"></div>
      <p style=" text-align: center;font-size: 22px;width: 94%;margin-left: 3%;font-family: SourceHanSansCN-Medium, SourceHanSansCN;font-weight: 500;color: #000000;">{{punName}}</p>
      <!--<p style=" margin-bottom: 30px; text-align: center;font-size: 18px;font-family: SourceHanSansCN-Medium, SourceHanSansCN;font-weight: 500;color: #000000;">({{fileNum}})</p>-->
      <!--<div  style="text-align: -webkit-center;" v-html="contentHtml"></div>-->

      <div style="height: 20px"></div>
      <div style="margin-left: 10%">
        <el-table
            :data="tableData"
            show-header="true"
            class="elTable"
            :row-style="{height: 50+'px',background: 'transparent !important'}"
            :cell-style="{padding:0+'px','text-align':'center',background: 'transparent !important','font-size': '16px','font-family': '思源黑体','font-weight': '400',color: 'rgb(0, 0, 0)','border-color':'rgb(0, 0, 0)'}"
            :header-cell-style="{height: 50+'px',padding:0+'px','text-align':'center','font-size': '16px','font-family': '思源黑体','font-weight': '700',color: 'rgb(0, 0, 0)','border-color':'rgb(0, 0, 0)'}"
            border
            style="width: 90%">
          <el-table-column
              align="center"
              prop="title"
              label="行政处罚决定书文号"
          >
          </el-table-column>
          <el-table-column
              prop="name"
              :label="fileNum">
          </el-table-column>
        </el-table>
      </div>


      <div style="height: 40px"></div>
    </div>
  </div>

</template>

<script>
  import { getPunishBankDetailApi} from "@/api/weeklyApi";

  export default {
    data() {
      return {
        activeName: "first",
        contentHtml: "",
        punName: "",
        fileNum: "",
        tableData: []
      };
    },
    mounted() {

      this.getDetail()
    },
    methods: {
      getDetail(){
        getPunishBankDetailApi({
          params: {
            punishId: this.$route.query.id,
            key: this.$route.query.key,
            date: this.$route.query.date,
            userId: this.$route.query.userId,
            groupId: this.$route.query.groupId,
          },
        }).then((ret) => {
          console.info(ret.data.data)
          this.tableData = ret.data.data.textList;
          this.punName = ret.data.data.name;
          this.fileNum = ret.data.data.fileNum;
        });
      }
    },
  };
</script>

<style scoped lang="scss" >
.MsoNormalTable{
  width: 80% !important;
  margin-bottom: 20px !important;
}
.el-main-weekly-detail {
  width: 100%!important;
  height: 100%!important;
  background:  #FFFFFF 100% !important;
}

.elTable{
  border: 1px rgb(0, 0, 0) solid;
}

/* 为第一个header定义样式 */
.el-table th:first-child .cell {
  color: rgb(0, 0, 0);
  font-weight: 900;
}

/* 为第二个header定义样式 */
.el-table th:nth-child(2) .cell {
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.el-table td:first-child .cell {
  color: rgb(0, 0, 0);
  font-weight: 900;
}

</style>