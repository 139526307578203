<template>
  <div>
    <div class="loginbox">
      <div class="login_title">
         <img src="../../../assets/image/login/logo-hzcx.png" style="width: 0.97rem;height: 0.24rem;display: flex;position: absolute;top: 0.29rem;right: 0.33rem" alt="" />
        <span >合规数据库</span>
      </div>
      <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
          label-position="left"
          auto-complete="on"
      >
        <el-form-item prop="account">
          <el-input
              :validate-event="false"
              prop="account"
              ref="account"
              v-model="loginForm.account"
              placeholder="请输入账号"
              name="username11"
              type="text"
              tabindex="1"
              @keyup.enter.native="handleLogin"
          >
            <i slot="suffix" class="username1"
            ><img src="@/assets/image/login/user2.png" alt=""
            /></i>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
              :validate-event="false"
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="请输入密码"
              name="password"
              tabindex="2"
              @keyup.enter.native="handleLogin"
          >
            <i slot="suffix" class="password1"
            ><img src="@/assets/image/login/psw1.png"  alt=""
            /></i>
          </el-input>
        </el-form-item>

        <el-form-item class="forget forget_password"> </el-form-item>
        <el-button
            :loading="loading"
            type="primary"
            style="width: 100%;font-size: 0.11rem;height: 0.25rem;margin-top: 0.05rem;font-family: AppleSystemUIFont;background: linear-gradient( 142deg, #00A4F7 0%, #0229B6 100%);box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25), inset 0px -1px 3px 0px rgba(0,0,0,0.25), inset 0px 1px 3px 0px rgba(255,255,255,0.25);border-radius: 4px;"
            @click.native.prevent="handleLogin('loginForm')"
        >立即登录</el-button
        >

      </el-form>
    </div>

  </div>
</template>

<script>
  // import getLogin from "@/api/login";
  import { doLoginApi } from "@/api/loginApi";
  import logoImg from "../../../assets/image/login_images/WechatIMG69.jpeg";
  import { checkUser, validatePass } from "../../../vaildator/loginValidator";
  import {eventBus} from "@/main";
  export default {
    data() {
      return {
        // saveAllList: [],
        ruleForm: {
          pass: "",
          // checkPass: "",
          user: "",
        },
        errorMsg: "",
        logo: logoImg,
        loginForm: {
          account: "",
          password: "",
          captcha: "",
        },
        loginRules: {
          account: [
            { required: true, trigger: "blur", message: "请输入用户名" },
          ],
          password: [
            { required: true, trigger: "blur", message: "请输入密码" },
            { type: 'number', message: "年龄必须为数字值" },
          ],
          captcha: [{ required: true, trigger: "blur", message: "请输入验证码" }],
        },
        loading: false,
        passwordType: "password",
        redirect: undefined,
        captchaBase64: undefined,
        uuid: undefined,
      };
    },
    methods: {
      submitForm(formName) {
        this.$router.push("/home");
      },

      async handleLogin(formName) {

        var ret = await doLoginApi({
          account: this.loginForm.account,
          password: this.loginForm.password,
        });

        if (ret.data.code === 200) {
          this.$store.commit("updateData", ret.data.data.lineList);
          this.$store.commit("usernameData", ret.data.data.username);
          localStorage.setItem("token", ret.data.data.token);
          localStorage.setItem("tenantId", ret.data.data.tenantId);
          if(localStorage.getItem("tenantId") == 11){
            if (item != 3){
              this.path = "auth";
            }
          }
          this.$router.push({
            path: "/header/punish",
            query: {
              type: 3,
            },
          });
        }
        if (ret.data.code === 500) {
          this.$message.error({
            message: ret.data.msg,
            duration: 2000,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  input[type="text"]:-webkit-autofill {
    -webkit-text-fill-color: #9199aa;
    box-shadow: 0 0 0px 50px #2b2b36 inset !important;
  }
  ::v-deep .el-input__inner {
    padding: 2px 10px 0px 40px;
    background-repeat: no-repeat;
    font-size: 16px;
    height: 52px;
  }

  .username1 {
    position: absolute;
    // top: 9px;
    left: -2.07rem;
    line-height: 62px;
  }
  .username1 img {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
  .password1 img {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
  .password1 {
    position: absolute;
    // top: 8px;
    left: -2.07rem;
    line-height: 60px;
  }
  .login-form {
    position: relative;
    width: 2.14rem;
    max-width: 100%;
    margin: 0.2rem auto 0;
  }

  .loginbox {
    display: inline-block;
    width: 4.06rem !important;
  }
  .login_title span {
    width: 1.11rem;
    height: 0.31rem;
    color: rgba(13, 26, 38, 1);
    font-weight: 900;
    font-size: 0.21rem;
    display: block;
    margin: 1.72rem auto 30px;
    font-family: PingFangSC-Semibold;
    letter-spacing: 2.29px;
    line-height: 32px;
  }
  .el-form-item {
    margin-bottom: 0.08rem;
  }
  .forget {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #1890ff;
    text-align: right;
  }

</style>