<template>
  <div>
    <div class="loginbox">
      <div class="login_title">
        <!-- <img src="../../../assets/image/login/合规数据库.png" alt="" /> -->
        <span class="txt1">合规数据库</span>
      </div>
      <div class="top_content">
        <div class="border_box left"></div>
        <div class="word">即点即用，一点合规</div>
        <div class="border_box left"></div>
      </div>
      <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
          label-position="left"
          auto-complete="on"
      >
        <el-form-item prop="account">
          <el-input
              :validate-event="false"
              prop="account"
              ref="account"
              v-model="loginForm.account"
              placeholder="账户"
              name="username11"
              type="text"
              tabindex="1"
              @keyup.enter.native="handleLogin"
          >
            <i slot="suffix" class="username1"
            ><img src="@/assets/image/login/user1.svg" alt=""
            /></i>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
              :validate-event="false"
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="密码"
              name="password"
              tabindex="2"
              @keyup.enter.native="handleLogin"
          >
            <i slot="suffix" class="password1"
            ><img src="@/assets/image/login/psw.svg" alt=""
            /></i>
          </el-input>
        </el-form-item>
        <div class="forget">忘记密码</div>
        <el-form-item class="forget forget_password"> </el-form-item>
        <el-button
            :loading="loading"
            type="primary"
            style="width: 100%; margin-bottom: 24px"
            @click.native.prevent="handleLogin('loginForm')"
        >登录</el-button
        >
        <div class="login_circle">
          <img src="@/assets/image/login/login-peitu.png" alt="" />
        </div>
      </el-form>
    </div>

  </div>
</template>

<script>
  // import getLogin from "@/api/login";
  import { doLoginApi } from "@/api/loginApi";
  import logoImg from "../../../assets/image/login_images/WechatIMG69.jpeg";
  import { checkUser, validatePass } from "../../../vaildator/loginValidator";
  export default {
    data() {
      return {
        // saveAllList: [],
        ruleForm: {
          pass: "",
          // checkPass: "",
          user: "",
        },
        errorMsg: "",
        logo: logoImg,
        loginForm: {
          account: "",
          password: "",
          captcha: "",
        },
        loginRules: {
          account: [
            { required: true, trigger: "blur", message: "请输入用户名" },
          ],
          password: [
            { required: true, trigger: "blur", message: "请输入密码" },
            { type: 'number', message: "年龄必须为数字值" },
          ],
          captcha: [{ required: true, trigger: "blur", message: "请输入验证码" }],
        },
        loading: false,
        passwordType: "password",
        redirect: undefined,
        captchaBase64: undefined,
        uuid: undefined,
      };
    },
    methods: {
      submitForm(formName) {
        this.$router.push("/home");
      },
      async handleLogin(formName) {
        // let this_ = this;
        // this_.$refs["loginForm"].validate((valid, fields) => {
        //   if (valid) {
        //     doLoginApi({
        //       username: this_.loginForm.username,
        //       password: this_.loginForm.password,
        //     }).then((ret) => {
        //       if (ret.data.code === 200) {
        //         this_.$store.commit("updateData", ret.data.data.lineList);
        //         this_.$store.commit("usernameData", ret.data.data.username);
        //         sessionStorage.setItem("token", ret.data.data.token);
        //         this_.$router.push("header/home");
        //       }
        //       if (ret.data.code === 500) {
        //         this_.error('ret.data.msg');
        //         this_.errors.code = Math.random();
        //         this_.$nextTick(() => {
        //           this_.errTips.code =
        //             "登录失败，" + (res.data.msg ? "，" + res.data.msg : "");
        //         });
        //       }
        //     });
        //   } else {
        //     return false;
        //   }
        // });

        var ret = await doLoginApi({
          account: this.loginForm.account,
          password: this.loginForm.password,
        });

        if (ret.data.code === 200) {
          this.$store.commit("updateData", ret.data.data.lineList);
          this.$store.commit("usernameData", ret.data.data.username);
          localStorage.setItem("token", ret.data.data.token);
          localStorage.setItem("tenantId", ret.data.data.tenantId);
          this.$router.push("header/home");
        }
        if (ret.data.code === 500) {
          this.$message.error({
            message: ret.data.msg,
            duration: 2000,
          });
          // this.$refs[formName].validate((valid) => {
          //   if (!valid) {
          //     return false;
          //   } else if (ret.data.code === 500) {
          //     console.log("error submit!!");
          //   }
          // });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  input[type="text"]:-webkit-autofill {
    -webkit-text-fill-color: #9199aa;
    box-shadow: 0 0 0px 50px #2b2b36 inset !important;
  }
  ::v-deep .el-input__inner {
    padding: 2px 10px 0 30px;
    background-repeat: no-repeat;
    font-size: 16px;
  }
  // ::v-deep .username1 .el-input__inner {
  //   background-image: url("../../../assets/image/login/user.svg") !important;
  //   background-position: 3% 54%;
  //   z-index: 900;
  // }
  // ::v-deep .password1 .el-input__inner {
  //   background-image: url("../../../assets/image/login/lock.svg");
  //   background-position: 2% 70%;
  //   z-index: 900;
  // }
  .username1 {
    position: absolute;
    // top: 9px;
    left: -358px;
    line-height: 56px;
  }
  .username1 img {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .password1 img {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .password1 {
    position: absolute;
    // top: 8px;
    left: -358px;
    line-height: 55px;
  }
  .login-form {
    position: relative;
    width: 370px;
    max-width: 100%;
    margin: 50px auto 0;
  }

  .loginbox {
    display: inline-block;
    width: 590px !important;
  }
  .login_title span {
    width: 172px;
    height: 30px;
    color: rgba(13, 26, 38, 1);
    font-weight: 900;
    font-size: 32px;
    display: block;
    margin: 140px auto 30px;
    font-family: PingFangSC-Semibold;
    letter-spacing: 2.29px;
    line-height: 32px;
  }

  .top_content {
    display: flex;
    width: 368px;
    margin: 17px auto;
    justify-content: space-between;
  }
  .top_content > .left {
    width: 188px;
    height: 1px;
    background: #d9d9d9;
  }
  .top_content > .word {
    width: 270px;
    height: 22px;
    font-size: 14px;
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0, 0, 0, 0.5);
    line-height: 4px;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  }
  .el-form-item {
    margin-bottom: 24px;
  }
  .forget {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #1890ff;
    text-align: right;
  }
  .el-button--primary {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    letter-spacing: 8px;
    height: 40px;
    color: #ffffff;
  }
  .login_circle img {
    margin: 0px 0 0 335px;
    display: inline-block;
    width: 150px;
    height: 106px;
  }
</style>